import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "../../static/carousel.css"; // requires a loader
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Container } from "@mui/system";

const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer width="100%" url={url} playing={isSelected} muted />
);

const YoutubeGallery = () => {
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url) =>
    url.substr("https://www.youtube.com/embed/".length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url);
      return <img src={getVideoThumb(videoId)} />;
    });

  return (
    <div style={{ backgroundColor: "#202026" }}>
      <Container maxWidth="lg">
        <div style={{ textAlign: "center" }}>
          <h2
            style={{
              textAlign: "center",
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingBottom: "2em",
              color: "white",
              paddingTop: "2em",
            }}
          >
            Watch videos recorded with MicroTo4K{" "}
          </h2>
        </div>
        <Carousel
          axis={"vertical"}
          renderItem={customRenderItem}
          renderThumbs={customRenderThumb}
        >
          <YoutubeSlide
            key="youtube-1"
            url="https://www.youtube.com/embed/9f6fyclhklw"
          />
          <YoutubeSlide
            key="youtube-2"
            url="https://www.youtube.com/embed/sy5wVBDGN4Q"
          />
          <YoutubeSlide
            key="youtube-3"
            url="https://www.youtube.com/embed/rIcM2OXX8U8"
          />
          <YoutubeSlide
            key="youtube-4"
            url="https://www.youtube.com/embed/hGEX2bkscHI"
          />
          <YoutubeSlide
            key="youtube-5"
            url="https://www.youtube.com/embed/hLWpmKdXN5E"
          />
          <YoutubeSlide
            key="youtube-6"
            url="https://www.youtube.com/embed/wPK1bZwlbLM"
          />
          <YoutubeSlide
            key="youtube-7"
            url="https://www.youtube.com/embed/G1sn1KgrKu0"
          />
          <YoutubeSlide
            key="youtube-8"
            url="https://www.youtube.com/embed/odPlOdTZymM"
          />
          {/* <YoutubeSlide
            key="youtube-9"
            url="https://www.youtube.com/embed/37sXZ83uD_A"
          />
          <YoutubeSlide
            key="youtube-10"
            url="https://www.youtube.com/embed/yLYJg07vKKw"
          />
          <YoutubeSlide
            key="youtube-11"
            url="https://www.youtube.com/embed/Yy8BV8NACV8"
          />
          <YoutubeSlide
            key="youtube-12"
            url="https://www.youtube.com/embed/PtW8EW6UOw4"
          />
          <YoutubeSlide
            key="youtube-13"
            url="https://www.youtube.com/embed/ktChNbHXwdc"
          /> */}
        </Carousel>
        <a
          href="https://www.youtube.com/c/CustomSurgical"
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingBottom: "2em",
          }}
          id="Carousel"
        >
          <div class="btnsub">
            <YouTubeIcon />
            &nbsp; Subscribe
          </div>{" "}
        </a>
      </Container>
    </div>
  );
};

export default YoutubeGallery;
