import React from "react";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "../../static/testimonials.css";
import sunil from "./sunil.png";
import { Container, Grid } from "@mui/material";

const testimonials = [
  {
    image: sunil,
    testimony: "This is a great service!",
    name: "Dr. Sunil Mamtora",
    speciality: "Ophalmology and Founder. of Slit Lamp Studios ",
  },
  //   {
  //     image: "doctor2.jpg",
  //     testimony: "Highly recommend to everyone.",
  //     name: "Dr. Jane Smith",
  //     speciality: "Dermatologist",
  //   },
  //   {
  //     image: "doctor3.jpg",
  //     testimony: "I love this service!",
  //     name: "Dr. Ahmed Ali",
  //     speciality: "Neurologist",
  //   },
];

const Testimonials = () => {
  return (
    <Container maxWidth="lg">
      {/* <Swiper
        modules={[Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
      > */}
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={index}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <img
                src={testimonial.image}
                alt="Doctor"
                className="doctor-image"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              flexDirection={"column"}
              justifyContent={"space-between"}
              display={"flex"}
              marginLeft={{ xs: 0, sm: 2 }}
            >
              <p className="testimony">"{testimonial.testimony}"</p>
              <div className="name-speciality">
                <p className="name">{testimonial.name}</p>
                <p className="speciality">{testimonial.speciality}</p>
              </div>
            </Grid>
          </Grid>
        </SwiperSlide>
      ))}
      {/* </Swiper> */}
    </Container>
  );
};

export default Testimonials;
